import React from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import clsx from "clsx";
// core components
import tableStyle from "./tables";
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';

import {
  Card,
  Button,
  CardHeader,
  TableContainer,
  Box,
  ButtonGroup,
  OutlinedInput,
  CardActions,
  Avatar,
  IconButton,
} from "@material-ui/core";
import AddNewGeoJsonDialog from "../AddNewGeoJsonDialog/AddNewGeoJsonDialog";
import { useDispatch, useSelector } from "react-redux";
import { GeoJson } from "../../containers/admin/GeoJsonsPage/reducer";
import {
  changeGeoJsonTypeAction,
  deleteGeoJsonAction,
} from "../../containers/admin/GeoJsonsPage/action";
import { HomePageState } from "../../containers/admin/Home/reducer";
import { DashboardAppState } from "../../store";
import MoveGeoJsonDialog from "../MoveGeoJsonDialog/MoveGeoJsonDialog";
import { useHistory } from "react-router-dom";

export default function GeoJsonsTable(props: { geoJsons: Array<GeoJson> }) {
  const [open, setOpen] = React.useState(false);
  const [selectOpen, setSelectOpen] = React.useState(false);
  const [selectedGeoJson, setGeoJson] = React.useState<GeoJson | undefined>(undefined);
  const dispatch = useDispatch();
  const { currentCategory, categories } = useSelector<
    DashboardAppState,
    HomePageState
  >((state) => state.homePageManagement);
  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectClose = () => {
    setSelectOpen(false);
  };

  const addNewJson = () => {
    setOpen(true);
  };
  const classes = tableStyle();
  const { geoJsons } = props;

  const deleteGeoJson = (id: string) => {
    dispatch(deleteGeoJsonAction(id));
  };
  const onTypeChange = (value: string) => {
    dispatch(changeGeoJsonTypeAction(value));
  };
  const history = useHistory();

  return (
    <div>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          className={classes.cardHeader}
          avatar={
            <Avatar className={classes.avatar}>
              <IconButton onClick={()=> history.goBack()}><ArrowBackIosRoundedIcon className={classes.arrowIcon} /></IconButton>
            </Avatar>
          }
          title={`${currentCategory?.name}/Geo Jsons`}
          titleTypographyProps={{
            component: Box,
            marginBottom: "0!important",
            variant: "h3",
          }}
          action={
            <Button
              component="button"
              fullWidth
              variant="contained"
              id="add-button"
              onClick={addNewJson}
            >
              Add New
            </Button>
          }
        ></CardHeader>
        <CardActions>
          <div>
            <OutlinedInput
              // value={values.type || ""}
              placeholder="Filter By Type"
              required
              fullWidth
              onChange={(event) => {
                onTypeChange(event.target.value);
              }}
              name="type"
              id="type"
            />
          </div>
        </CardActions>
        <TableContainer>
          <Box component={Table} alignItems="center" marginBottom="0!important">
            <TableHead>
              <TableRow>
                <TableCell
                  className={clsx(
                    classes.tableCellRoot,
                    classes.tableCellRootHead
                  )}
                >
                  #
                </TableCell>
                <TableCell
                  className={clsx(
                    classes.tableCellRoot,
                    classes.tableCellRootHead
                  )}
                >
                  Title
                </TableCell>
                <TableCell
                  className={clsx(
                    classes.tableCellRoot,
                    classes.tableCellRootHead
                  )}
                >
                  Type
                </TableCell>
                <TableCell
                  className={clsx(
                    classes.tableCellRoot,
                    classes.tableCellRootHead
                  )}
                >
                  Description
                </TableCell>
                <TableCell
                  className={clsx(
                    classes.tableCellRoot,
                    classes.tableCellRootHead
                  )}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {geoJsons.map((geoJson) => {
                return (
                  <TableRow key={geoJson.documentID}>
                    <TableCell className={classes.tableCellRoot}>
                      {geoJsons.indexOf(geoJson) + 1}
                    </TableCell>
                    <TableCell className={classes.tableCellRoot}>
                      {geoJson.title}
                    </TableCell>
                    <TableCell className={classes.tableCellRoot}>
                      {geoJson.type}
                    </TableCell>
                    <TableCell
                      className={clsx(classes.tableCellRoot, classes.tableCell)}
                    >
                      {geoJson.description}
                    </TableCell>
                    <TableCell className={classes.tableCellRoot}>
                      <ButtonGroup
                        color="primary"
                        aria-label="contained primary button group"
                      >
                        <Button
                          component="button"
                          size="small"
                          // variant="contained"
                          id="copy-btn"
                          // color="secondary"
                          onClick={() => {
                            setGeoJson(geoJson);
                            setSelectOpen(true);
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          component="button"
                          size="small"
                          // variant="contained"
                          id="copy-btn"
                          // color="secondary"
                          onClick={() => {
                            deleteGeoJson(geoJson.documentID);
                          }}
                        >
                          Delete
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Box>
        </TableContainer>
      </Card>
      <AddNewGeoJsonDialog open={open} handleClose={handleClose} />
      <MoveGeoJsonDialog
        open={selectOpen}
        handleClose={handleSelectClose}
        currentCategory={currentCategory}
        geoJson={selectedGeoJson}
        categories={categories}
      />
    </div>
  );
}
