import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  Grid,
  DialogActions,
  Button,
  OutlinedInput,
  FormLabel,
} from "@material-ui/core";
import componentStyles from "../ForgotPasswordDialog/dialog.js";
import { Clear } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import formStyle from "../GeoJsonForm/formStyle.js";
import { addCategoryAction } from "../../containers/admin/Home/action";
export default function AddNewCategory(props: {
  open: boolean;
  handleClose: any;
}): JSX.Element {
  const dialogclasses = componentStyles();
  const { open, handleClose } = props;

  const dispatch = useDispatch();

  const classes = formStyle();
  const initialValues = {
    name: "",
  };

  const yupSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });
  const addNewCategory = (values: any) => {
    handleClose();
    dispatch(addCategoryAction(values.name));
  };

  return (
    <div>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
      >
        <div className={dialogclasses.dialogHeader}>
          <Typography
            variant="h5"
            component="h5"
            className={dialogclasses.dialogTitle}
          >
            Add Category
          </Typography>
          <IconButton onClick={handleClose}>
            <Clear />
          </IconButton>
        </div>

        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={yupSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              addNewCategory(values);
              setSubmitting(false);
              resetForm({});
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              resetForm,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid
                  container
                  justify="center"
                  className={classes.gridStyle}
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <FormLabel>Name</FormLabel>
                    <OutlinedInput
                      value={values.name || ""}
                      placeholder="Name"
                      required
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="name"
                      id="name"
                    />
                    {errors.name && touched.name && (
                      <div id="type-error" className={classes.error}>
                        {errors.name}
                      </div>
                    )}
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    color="secondary"
                    variant="contained"
                  >
                    Close
                  </Button>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    color="primary"
                    variant="contained"
                  >
                    Add
                  </Button>
                </DialogActions>

                <DialogActions></DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}
