import React from "react";
import { useLocation, Link } from "react-router-dom";
// @material-ui/core components
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import Toolbar from "@material-ui/core/Toolbar";
// core components
import componentStyles from "./style";


export default function Sidebar(props: { routes: any; logo: any; dropdown: any; input?: any; }) {
  const { routes, logo, dropdown, input }= props;
  const classes = componentStyles();
  let logoImage = (
    <img alt={logo.imgAlt} className={classes.logoClasses} src={logo.imgSrc} />
  );
  let logoObject =
    logo && logo.innerLink ? (
      <Link to={logo.innerLink} className={classes.logoLinkClasses}>
        {logoImage}
      </Link>
    ) : logo && logo.outterLink ? (
      <a href={logo.outterLink} className={classes.logoLinkClasses}>
        {logoImage}
      </a>
    ) : null;
  return (
    <>
      <Hidden mdUp implementation="css">
        <AppBar position="relative" color="default" elevation={0}>
          <Toolbar>
            <Container
              display="flex!important"
              justifyContent="space-between"
              alignItems="center"
              marginTop=".75rem"
              marginBottom=".75rem"
              component={Box}
              padding="0!important"
            >
              {logoObject}
              {dropdown}
            </Container>
          </Toolbar>
        </AppBar>
      </Hidden>
    </>
  );
}

Sidebar.defaultProps = {
  routes: [],
};


