// import { NotificationManager } from "react-notifications";

import { ConsoleHelper } from "../../../utils/helpers";
import { GeoJson } from "./reducer";

export enum geoJsonsPageActionTypes {
  GET_GEO_JSONS = "geo_jsons_page/get_geo_jsons",
  GET_GEO_JSONS_SUCCESS = "geo_jsons_page/get_geo_jsons_success",
  GET_GEO_JSONS_FAILURE = "geo_jsons_page/get_geo_jsons_failure",
  ADD_GEO_JSON = "geo_jsons_page/add_geo_json",
  ADD_GEO_JSON_SUCCESS = "geo_jsons_page/add_geo_json_success",
  ADD_GEO_JSON_FAILURE = "geo_jsons_page/add_geo_json_failure",
  SET_GPX_FILES = "geo_jsons_page/set_gpx_files",
  SET_IMAGE_FILE = "geo_jsons_page/set_image_file",
  DELETE_GEO_JSON = "geo_jsons_page/delete_geo_json",
  DELETE_GEO_JSON_SUCCESS = "geo_jsons_page/delete_geo_json_success",
  DELETE_GEO_JSON_FAILURE = "geo_jsons_page/delete_geo_json_failure",
  EDIT_GEO_JSON = "geo_jsons_page/edit_geo_json",
  EDIT_GEO_JSON_SUCCESS = "geo_jsons_page/move_geo_json_success",
  EDIT_GEO_JSON_FAILURE = "geo_jsons_page/move_geo_json_failure",
  CHANGE_GEO_JSON_TYPE = "geo_jsons_page/change_geo_json_type",
}

export interface DeleteGeoJsonAction {
  type: geoJsonsPageActionTypes.DELETE_GEO_JSON;
  geoJsonId: string;
}

export interface ChangeGeoJsonTypeAction {
  type: geoJsonsPageActionTypes.CHANGE_GEO_JSON_TYPE;
  geoJsonType: string;
}

export interface DeleteGeoJsonSuccessAction {
  type: geoJsonsPageActionTypes.DELETE_GEO_JSON_SUCCESS;
}

export interface DeleteGeoJsonFailureAction {
  type: geoJsonsPageActionTypes.DELETE_GEO_JSON_FAILURE;
  err: string;
}

export interface GetGeoJsonsAction {
  type: geoJsonsPageActionTypes.GET_GEO_JSONS;
  categoryId: string;
}

interface GetGeoJsonsSuccessAction {
  type: geoJsonsPageActionTypes.GET_GEO_JSONS_SUCCESS;
  geoJsons: Array<GeoJson>;
}

interface GetGeoJsonsFailureAction {
  type: geoJsonsPageActionTypes.GET_GEO_JSONS_FAILURE;
  err: string;
}

export interface AddGeoJsonAction {
  type: geoJsonsPageActionTypes.ADD_GEO_JSON;
  title: string;
  geoType: string;
  categoryId: string;
  description: string;
  file: any;
  image: any;
  fileType: string;
}

interface AddGeoJsonSuccessAction {
  type: geoJsonsPageActionTypes.ADD_GEO_JSON_SUCCESS;
}

interface SetGPXFileAction {
  type: geoJsonsPageActionTypes.SET_GPX_FILES;
  file: any;
}

interface SetImageAction {
  type: geoJsonsPageActionTypes.SET_IMAGE_FILE;
  image: any;
}

interface AddGeoJsonFailureAction {
  type: geoJsonsPageActionTypes.ADD_GEO_JSON_FAILURE;
  err: string;
}

export interface EditGeoJsonAction {
  type: geoJsonsPageActionTypes.EDIT_GEO_JSON;
  geoJsonId: string;
  categoryId: string;
  title: string,
  geoType: string,
  description: string,
}

interface EditGeoJsonSuccessAction {
  type: geoJsonsPageActionTypes.EDIT_GEO_JSON_SUCCESS;
}

interface EditGeoJsonFailureAction {
  type: geoJsonsPageActionTypes.EDIT_GEO_JSON_FAILURE;
  err: string;
}

export type geoJsonsPageActions =
  | GetGeoJsonsAction
  | GetGeoJsonsSuccessAction
  | GetGeoJsonsFailureAction
  | AddGeoJsonAction
  | AddGeoJsonSuccessAction
  | AddGeoJsonFailureAction
  | DeleteGeoJsonAction
  | DeleteGeoJsonSuccessAction
  | DeleteGeoJsonFailureAction
  | EditGeoJsonAction
  | EditGeoJsonSuccessAction
  | EditGeoJsonFailureAction
  | SetImageAction
  | ChangeGeoJsonTypeAction
  | SetGPXFileAction;

export function deleteGeoJsonAction(id: string): DeleteGeoJsonAction {
  ConsoleHelper("setGPXFileAction fired");
  return {
    type: geoJsonsPageActionTypes.DELETE_GEO_JSON,
    geoJsonId: id,
  };
}

export function changeGeoJsonTypeAction(
  geoJsonType: string
): ChangeGeoJsonTypeAction {
  ConsoleHelper("change geoJsonType action fired");
  return {
    type: geoJsonsPageActionTypes.CHANGE_GEO_JSON_TYPE,
    geoJsonType: geoJsonType,
  };
}

export function deleteGeoJsonSuccessAction(): DeleteGeoJsonSuccessAction {
  return {
    type: geoJsonsPageActionTypes.DELETE_GEO_JSON_SUCCESS,
  };
}

export function deleteGeoJsonFailureAction(
  err: string
): DeleteGeoJsonFailureAction {
  return {
    type: geoJsonsPageActionTypes.DELETE_GEO_JSON_FAILURE,
    err: err,
  };
}

export function setGPXFileAction(uploadedFile: any): SetGPXFileAction {
  ConsoleHelper("setGPXFileAction fired");
  return {
    type: geoJsonsPageActionTypes.SET_GPX_FILES,
    file: uploadedFile,
  };
}

export function setImageAction(uploadedImage: any): SetImageAction {
  ConsoleHelper("setImageAction fired");
  return {
    type: geoJsonsPageActionTypes.SET_IMAGE_FILE,
    image: uploadedImage,
  };
}

export function getGeoJsonsAction(id: string): GetGeoJsonsAction {
  ConsoleHelper("GetGeoJsonsAction fired");
  return {
    type: geoJsonsPageActionTypes.GET_GEO_JSONS,
    categoryId: id,
  };
}

export function getGeoJsonsSuccessAction(
  geoJsons: Array<GeoJson>
): GetGeoJsonsSuccessAction {
  return {
    type: geoJsonsPageActionTypes.GET_GEO_JSONS_SUCCESS,
    geoJsons: geoJsons,
  };
}

export function getGeoJsonsFailureAction(
  err: string
): GetGeoJsonsFailureAction {
  // eslint-disable-next-line no-console
  console.error("getGeoJsonsFailureAction", err);
  return {
    type: geoJsonsPageActionTypes.GET_GEO_JSONS_FAILURE,
    err: err,
  };
}

export function addGeoJsonAction(
  title: string,
  type: string,
  categoryId: string,
  description: string,
  file: any,
  image: any,
  fileType: string,
): AddGeoJsonAction {
  return {
    type: geoJsonsPageActionTypes.ADD_GEO_JSON,
    title: title,
    geoType: type,
    categoryId: categoryId,
    description: description,
    file: file,
    image: image,
    fileType: fileType,
  };
}

export function addGeoJsonSuccessAction(): AddGeoJsonSuccessAction {
  return {
    type: geoJsonsPageActionTypes.ADD_GEO_JSON_SUCCESS,
  };
}

export function addGeoJsonFailureAction(err: string): AddGeoJsonFailureAction {
  return {
    type: geoJsonsPageActionTypes.ADD_GEO_JSON_FAILURE,
    err: err,
  };
}

export function editGeoJsonAction(
  geoJsonId: string,
  categoryId: string,
  title: string,
  geoType: string,
  description: string,
): EditGeoJsonAction {
  return {
    type: geoJsonsPageActionTypes.EDIT_GEO_JSON,
    geoJsonId: geoJsonId,
    categoryId: categoryId,
    title: title,
  geoType: geoType,
  description: description,
  };
}

export function editGeoJsonSuccessAction(): EditGeoJsonSuccessAction {
  return {
    type: geoJsonsPageActionTypes.EDIT_GEO_JSON_SUCCESS,
  };
}

export function editGeoJsonFailureAction(
  err: string
): EditGeoJsonFailureAction {
  return {
    type: geoJsonsPageActionTypes.EDIT_GEO_JSON_FAILURE,
    err: err,
  };
}
