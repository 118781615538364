import Tv from "@material-ui/icons/Tv";
import VpnKey from "@material-ui/icons/VpnKey";
import GeoJsonsPage from "./containers/admin/GeoJsonsPage/GeoJsonPage";
import Dashboard from "./containers/admin/Home/Home";
import Login from "./containers/auth/Login/Login";

var routes = [
  {
    path: "/geoJsons",
    name: "GeoJsons",
    icon: Tv,
    iconColor: "Primary",
    component: GeoJsonsPage,
    layout: "/admin",
    inSideBar: false,
  },
  {
    path: "/categories",
    name: "Dashboard",
    icon: Tv,
    iconColor: "Primary",
    component: Dashboard,
    layout: "/admin",
    inSideBar: true,
  },

  {
    path: "/login",
    name: "Login",
    icon: VpnKey,
    iconColor: "Info",
    component: Login,
    layout: "/auth",
    inSideBar: false,
  },
];
export default routes;
