import {ERROR_HIGH} from "../../utils/colorCodes"
import { makeStyles } from "@material-ui/core/styles";
import { primaryColor } from "../../utils/style";


const formStyle = makeStyles((theme) => ({
    error:{
        color:ERROR_HIGH,
        fontSize:"12px"
    },
    gridStyle:{
        maxWidth: 600, 
        paddingBottom: "20px"
    },
    addButton:{
              backgroundColor: primaryColor[0],
              color: "white",
              fontSize: 16,
              fontWeight: 600,
              marginTop: 19,
              width: "80%",
              height: 50,
              textTransform: "none",
              position: "relative",   
    },
    dropzone:{
        textAlign: "center",
        padding: "20px",
        border: "3px dashed #eeeeee",
        backgroundColor: "#fafafa",
        color: "#bdbdbd",
      }
    
}));

export default formStyle;