import React from "react";
// @material-ui/core components

import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

// core components
import componentStyles from "./auth-header.js";


const AuthHeader = () => {
  const classes = componentStyles();
  const theme = useTheme();
  return (
    <div>
      <Box
        className={classes.header}
        position="relative"
        paddingTop="8rem"
        paddingBottom="8rem"
      >
        <Container maxWidth="xl">
          <Box marginBottom="6rem" textAlign="center">
            <Box
              component={Grid}
              // container
              justifyContent="center"
              color={theme.palette.background.default}
            >
              
            </Box>
          </Box>
        </Container>
        <Box
          position="absolute"
          zIndex="100"
          height="70px"
          top="auto"
          bottom="0"
          // pointerEvents="none"
          left="0"
          right="0"
          width="100%"
          overflow="hidden"
          
        >
         
            <Box
              component="polygon"
             
            />
          </Box>
        </Box>
     
    </div>
  );
};

export default AuthHeader;
