/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["draft"] }] */
import produce from "immer";
import { toast } from "react-toastify";
import { HomePageActions, homePageActionTypes } from "./action";
import Toast from "light-toast";
import { ConsoleHelper } from "../../../utils/helpers";
export interface Category {
  documentID: string;
  name: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
}

export interface HomePageState {
  categories: Array<Category>;
  getCategoriesLoading: boolean;
  currentCategory: Category | undefined;
  addCategoryLoading: boolean;
  addCategoryError: { hasError: boolean; description: string };
  getCategoriesError: { hasError: boolean; description: string };
}

const initialState: HomePageState = {
  categories: [],
  getCategoriesLoading: true,
  currentCategory: undefined,
  addCategoryError: { hasError: false, description: "" },
  addCategoryLoading: false,
  getCategoriesError: { hasError: false, description: "" },
};

export default function homePageReducer(
  state: HomePageState = initialState,
  action: HomePageActions
): HomePageState {
  return produce(state, (draft) => {
    switch (action.type) {
      case homePageActionTypes.GET_CATEGORIES: {
        draft.getCategoriesLoading = true;
        Toast.loading("Loading... Please wait.");
        break;
      }
      case homePageActionTypes.SET_CURRENT_CATEGORY: {
        draft.currentCategory = action.category;
        break;
      }
      case homePageActionTypes.GET_CATEGORIES_SUCCESS: {
        draft.getCategoriesLoading = false;
        draft.getCategoriesError = { hasError: false, description: "" };
        draft.categories = action.categories;
        ConsoleHelper(action.categories);
        Toast.hide();

        break;
      }
      case homePageActionTypes.GET_CATEGORIES_FAILURE: {
        draft.getCategoriesLoading = false;
        draft.getCategoriesError = { hasError: true, description: action.err };
        Toast.hide();
        toast.error(action.err);
        break;
      }
      case homePageActionTypes.ADD_CATEGORY: {
        draft.addCategoryLoading = true;
        Toast.loading("Loading... Please wait.");
        break;
      }
      case homePageActionTypes.ADD_CATEGORY_SUCCESS: {
        draft.addCategoryLoading = false;
        Toast.hide();
        toast.success("Category added successfully");
        break;
      }
      case homePageActionTypes.ADD_CATEGORY_FAILURE: {
        draft.addCategoryLoading = false;
        draft.addCategoryError = { hasError: true, description: action.err };
        Toast.hide();
        toast.error(action.err);

        break;
      }
      case homePageActionTypes.DELETE_CATEGORY: {
        Toast.loading("Loading... Please wait.");
        break;
      }
      case homePageActionTypes.DELETE_CATEGORY_SUCCESS: {
        Toast.hide();
        toast.success("Category deleted successfully");

        break;
      }
      case homePageActionTypes.DELETE_CATEGORY_FAILURE: {
        Toast.hide();
        toast.error(action.err);

        break;
      }

      default:
        break;
    }
  });
}
