import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  IconButton,
  MenuItem,
  Select,
  Grid,
  FormLabel,
  DialogActions,
  OutlinedInput,
} from "@material-ui/core";

import * as Yup from "yup";
import componentStyles from "./style.js";
import { Clear } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import React from "react";
import { Category } from "../../containers/admin/Home/reducer";
import { editGeoJsonAction } from "../../containers/admin/GeoJsonsPage/action";
import { Formik } from "formik";
import { GeoJson } from "../../containers/admin/GeoJsonsPage/reducer.js";

export default function MoveGeoJsonDialog(props: {
  open: boolean;
  handleClose: any;
  currentCategory: Category | undefined;
  categories: Category[];
  geoJson: GeoJson | undefined;
}): JSX.Element {
  const classes = componentStyles();
  const { open, handleClose, currentCategory, categories, geoJson } = props;
  const dispatch = useDispatch();

  const [categoryId, setCategoryId] = React.useState<string | undefined>(
    currentCategory?.documentID
  );
  const [selectOpen, setSelectOpen] = React.useState(false);

  

  const yupSchema = Yup.object().shape({
    title: Yup.string().required("Name is required"),
    type: Yup.string().required("Type is required"),
    description: Yup.string().required("description is required"),
  });

  const handleChangeCategory = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCategoryId(event.target.value as string);
  };

  const handleSelectClose = () => {
    setSelectOpen(false);
  };

  const handleSelectOpen = () => {
    setSelectOpen(true);
  };
  const initialValues = {
    title: geoJson?.title,
    type: geoJson?.type,
    description: geoJson?.description,
  };


  const editGeoJson = (values: any) => {
    if (typeof categoryId === "string" && typeof geoJson?.documentID === "string") {
      handleClose();
      dispatch(
        editGeoJsonAction(
          geoJson?.documentID, 
          categoryId,
          values.title,
          values.type,
          values.description,
        )
      );
    }
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={open} 
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <Dialog maxWidth="lg"  onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}> */}

        <div className={classes.dialogHeader}>
          <Typography
            variant="h5"
            component="h5"
            className={classes.dialogTitle}
          >
            Move GeoJson
          </Typography>
          <IconButton onClick={handleClose}>
            <Clear />
          </IconButton>
        </div>

        <DialogContent>

        <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={yupSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          
          editGeoJson(values);
          setSubmitting(false);
          resetForm({});

        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
          /* and other goodies */
        }) => (
          
          <form onSubmit={handleSubmit}>
            <Grid
              container
              justify="center"
              className={classes.gridStyle}
              spacing={2}
            >
              <Grid item xs={12} sm={4} md={4}>
                <FormLabel>Title</FormLabel>
                <OutlinedInput
                  value={values.title || ""}
                  placeholder="Title"
                  required
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="title"
                  id="title"
                />
                {errors.title && touched.title && (
                  <div id="type-error" className={classes.error}>
                    {errors.title}
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormLabel>Type</FormLabel>
                <OutlinedInput
                  value={values.type || ""}
                  placeholder="Type"
                  required
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="type"
                  id="type"
                />
                {errors.type && touched.type && (
                  <div id="type-error" className={classes.error}>
                    {errors.type}
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormLabel>Category</FormLabel>
                <br />
                <Select
                 fullWidth
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={selectOpen}
              onClose={handleSelectClose}
              onOpen={handleSelectOpen}
              value={categoryId}
              onChange={handleChangeCategory}
            >
              {categories.map((category) => {
                return (
                  <MenuItem value={category.documentID}>
                    {category.name}
                  </MenuItem>
                );
              })}
            </Select>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <FormLabel>Description</FormLabel>
                <OutlinedInput
                  multiline={true}
                  rows={3}
                  placeholder="Description"
                  required
                  fullWidth
                  value={values.description || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="description"
                  id="description"
                />
                {errors.description && touched.description && (
                  <div id="description-error" className={classes.error}>
                    {errors.description}
                  </div>
                )}
              </Grid>
            </Grid>

            <DialogActions>
              <Button
                onClick={handleClose}
                color="secondary"
                variant="contained"
              >
                Close
              </Button>
              <Button
                type="submit"
                disabled={
                  isSubmitting
                }
                color="primary"
                variant="contained"
              >
                Edit
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>  
        </DialogContent>
      </Dialog>
    </div>
  );
}
