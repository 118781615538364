import { Dialog, DialogContent, Typography, Button, IconButton, Box,  FilledInput, FormControl,  InputAdornment } from "@material-ui/core";

import componentStyles from "./dialog.js";
import { Clear, Email} from "@material-ui/icons";
import { Formik } from "formik";
import * as Yup from "yup";
import { forgotPasswordAction } from "../../containers/auth/Login/action";
import { useDispatch } from "react-redux";

export default function ForgotPasswordDialog(props: { open: boolean, handleClose: any }): JSX.Element {
    const classes = componentStyles();
    const { open, handleClose } = props;
    const dispatch = useDispatch();
    const initialValues = {
        email: '',
       

    }
    const yupSchema = Yup.object().shape({
        email: Yup.string().required("Email address is required").email("Email address is not in valid format"),
       
    });

    const forgotPassword = (email: string) => {
        dispatch(forgotPasswordAction(email));
    }


    return (
        <div>
            <Dialog
                open={open}
                // TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                {/* <Dialog maxWidth="lg"  onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}> */}

                <div className={classes.dialogHeader}>
                    <Typography
                        variant="h5"
                        component="h5"
                        className={classes.dialogTitle}
                    >
                        Forgot Password
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <Clear />
                    </IconButton>
                </div>


                <DialogContent>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={yupSchema}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            forgotPassword(values.email);
                            setSubmitting(false);
                            resetForm({});
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            resetForm,
                            /* and other goodies */
                        }) => (<form onSubmit={handleSubmit}>
                            <FormControl
                                variant="filled"
                                component={Box}
                                width="100%"
                                marginBottom="1rem!important"
                            >
                                <FilledInput
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="email"
                                    value={values.email}
                                    id="email"
                                    autoComplete="off"
                                    type="email"
                                    placeholder="Email"
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <Email />
                                        </InputAdornment>
                                    }
                                />
                                {errors.email && touched.email && (
                                    <div id="type-error" className={classes.error}>
                                        {errors.email}
                                    </div>
                                )}
                            </FormControl>

                            <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
                                <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
                                    Submit
                                </Button>
                            </Box>
                        </form>
                        )}
                    </Formik>
                </DialogContent>


            </Dialog>
        </div>
    );
}