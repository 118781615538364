import React from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import clsx from "clsx";
// core components
import tableStyle from "./tables";
import { Category } from "../../containers/admin/Home/reducer";
import {
  Card,
  Button,
  CardHeader,
  TableContainer,
  Box,
  ButtonGroup,
} from "@material-ui/core";
import { API_CLOUD } from "../../utils/apiEndPoints";
import { useDispatch } from "react-redux";
import {
  deleteCategoryAction,
  setCurrentCategoryAction,
} from "../../containers/admin/Home/action";
import AddNewCategory from "../AddCategory/AddCategory";
import { useHistory } from "react-router";

export default function CategoriesTable(props: {
  categories: Array<Category>;
}) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleClose = () => {
    setOpen(false);
  };

  const addNewCategory = () => {
    setOpen(true);
  };

  const deleteCategory = (id: string) => {
    dispatch(deleteCategoryAction(id));
  };
  const classes = tableStyle();
  const { categories } = props;

  const copyToClipBoard = () => {
    toast.success("URL copied to clipboard successfully!!");
  };

  const navigateToGeoJsonsPage = (category: Category) => {
    dispatch(setCurrentCategoryAction(category));
    history.push(`geoJsons`);
  };

  return (
    <div>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          className={classes.cardHeader}
          title="Categories"
          titleTypographyProps={{
            component: Box,
            marginBottom: "0!important",
            variant: "h3",
          }}
          action={
            <Button
              component="button"
              fullWidth
              variant="contained"
              id="add-button"
              onClick={addNewCategory}
            >
              Add New
            </Button>
          }
        ></CardHeader>
        <TableContainer>
          <Box component={Table} alignItems="center" marginBottom="0!important">
            <TableHead>
              <TableRow>
                <TableCell
                  className={clsx(
                    classes.tableCellRoot,
                    classes.tableCellRootHead
                  )}
                >
                  #
                </TableCell>
                <TableCell
                  className={clsx(
                    classes.tableCellRoot,
                    classes.tableCellRootHead
                  )}
                >
                  Name
                </TableCell>

                <TableCell
                  className={clsx(
                    classes.tableCellRoot,
                    classes.tableCellRootHead
                  )}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categories.map((category) => {
                return (
                  <TableRow key={category.documentID}>
                    <TableCell className={classes.tableCellRoot}>
                      {categories.indexOf(category) + 1}
                    </TableCell>
                    <TableCell className={classes.tableCellRoot}>
                      {category.name}
                    </TableCell>
                    <TableCell className={classes.tableCellRootCustom}>
                      <ButtonGroup
                        color="primary"
                        aria-label="contained primary button group"
                      >
                        <CopyToClipboard
                          text={API_CLOUD + "/georss/" + category.documentID}
                        >
                          <Button
                            component="button"
                            size="small"
                            // variant="contained"
                            id="copy-btn"
                            // color="secondary"
                            onClick={copyToClipBoard}
                          >
                            URL
                          </Button>
                        </CopyToClipboard>
                        <Button
                          component="button"
                          size="small"
                          // variant="contained"
                          id="copy-btn"
                          // color="secondary"
                          onClick={() => {
                            navigateToGeoJsonsPage(category);
                          }}
                        >
                          View GeoJsons
                        </Button>
                        <Button
                          component="button"
                          size="small"
                          // variant="contained"
                          id="copy-btn"
                          // color="secondary"
                          onClick={() => {
                            deleteCategory(category.documentID);
                          }}
                        >
                          Delete
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Box>
        </TableContainer>
      </Card>
      <AddNewCategory open={open} handleClose={handleClose} />
    </div>
  );
}
