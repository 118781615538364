import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import componentStyles from "./style";



const Header = () => {
  const classes = componentStyles();
 
  return (
    <>
      <div className={classes.header}>
        <Container
          component={Box}
          classes={{ root: classes.containerRoot }}
        >
          
        </Container>
      </div>
    </>
  );
};

export default Header;
