
export enum loginPageActionTypes {

  LOGIN = "login/login",
  LOGIN_SUCCESS = "login/login_success",
  LOGIN_FAILURE = "login/login_failure",
  FORGOT_PASSWORD = "login/forgot_password",
  FORGOT_PASSWORD_SUCCESS = "login/forgot_password_success",
  FORGOT_PASSWORD_FAILURE = "login/forgot_password_failure",
  LOGOUT = "login/logout",
  LOGOUT_SUCCESS = "login/logout_success",
  LOGOUT_FAILURE = "login/logout_failure",

}

export interface LoginAction {
  type: loginPageActionTypes.LOGIN;
  email: string;
  password: string;

}

interface LoginSuccessAction {
  type: loginPageActionTypes.LOGIN_SUCCESS;

}

interface LoginFailureAction {
  type: loginPageActionTypes.LOGIN_FAILURE;
  err: string;
}

export interface ForgotPasswordAction {
  type: loginPageActionTypes.FORGOT_PASSWORD;
  email: string

}

interface ForgotPasswordSuccessAction {
  type: loginPageActionTypes.FORGOT_PASSWORD_SUCCESS;

}

interface ForgotPasswordFailureAction {
  type: loginPageActionTypes.FORGOT_PASSWORD_FAILURE,
  err:string,

}

export interface LogoutAction {
  type: loginPageActionTypes.LOGOUT;

}

interface LogoutSuccessAction {
  type: loginPageActionTypes.LOGOUT_SUCCESS;

}

interface LogoutFailureAction {
  type: loginPageActionTypes.LOGOUT_FAILURE,
  err:string,

}




export type LoginPageActions =
  | LoginAction
  | LoginSuccessAction
  | LoginFailureAction
  | ForgotPasswordAction
  | ForgotPasswordSuccessAction
  | ForgotPasswordFailureAction
  | LogoutAction
  | LogoutSuccessAction
  | LogoutFailureAction;




export function loginAction(
  email: string,
  password: string,
): LoginAction {
  return {
    type: loginPageActionTypes.LOGIN,
    email:email,
    password:password,

  };
}

export function loginSuccessAction(): LoginSuccessAction {
  return {
    type: loginPageActionTypes.LOGIN_SUCCESS,
    
  };
}

export function loginFailureAction(err: string): LoginFailureAction {
 
  return {
    type: loginPageActionTypes.LOGIN_FAILURE,
    err: err
  };
}

export function forgotPasswordAction(email:string): ForgotPasswordAction {
  return {
    type: loginPageActionTypes.FORGOT_PASSWORD,
    email:email,
  };
}

export function forgotPasswordSuccessAction(): ForgotPasswordSuccessAction {
  return {
    type: loginPageActionTypes.FORGOT_PASSWORD_SUCCESS,

  };
}

export function forgotPasswordFailureAction(err: string): ForgotPasswordFailureAction {
  return {
    type: loginPageActionTypes.FORGOT_PASSWORD_FAILURE,
    err: err
  };
}

export function logoutSuccessAction(): LogoutSuccessAction {
  return {
    type: loginPageActionTypes.LOGOUT_SUCCESS,
    
  };
}

export function logoutFailureAction(err: string): LogoutFailureAction {
 
  return {
    type: loginPageActionTypes.LOGOUT_FAILURE,
    err: err
  };
}

export function logoutAction(): LogoutAction {
  return {
    type: loginPageActionTypes.LOGOUT,
    
  };
}




