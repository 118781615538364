import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
// core components
import componentStyles from "./admin-navbar";
import NavbarDropdown from "../Dropdowns/NavbarDropdown";
import { Link } from "react-router-dom";

export default function AdminNavbar() {

  const classes = componentStyles();
  return (
    <>
      <AppBar
        position="absolute"
        color="transparent"
        elevation={0}
        classes={{ root: classes.appBarRoot }}
      >
        <Toolbar disableGutters>
          <Container
            maxWidth={false}
            // component={Box}
            classes={{ root: classes.containerRoot }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              marginTop="0.5rem"
            >
              <div>
                <Link to="/admin/categories" className={classes.logoLinkClasses}>
                <img alt="Social Motion Gis" className={classes.logoClasses} src={require("../../assets/img/brand/argon-react-white.png").default} />
      </Link>
              </div>
              <Box display="flex" alignItems="center" width="auto">
                <NavbarDropdown />
              </Box>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
    </>
  );
}
