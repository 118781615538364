import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/remote-config";
import "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAQdLVD3_e8iO-Nd74t0BLkVN1F9KOvc-Q",
    authDomain: "social-motion-gis.firebaseapp.com",
    projectId: "social-motion-gis",
    storageBucket: "social-motion-gis.appspot.com",
    messagingSenderId: "1044642742660",
    appId: "1:1044642742660:web:3290348829c67f796c5dd1",
    measurementId: "G-NXR8JRZJYF"
};
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const remoteConfig = firebase.remoteConfig();
export const storageRef = firebase.storage().ref();

// @ts-ignore
remoteConfig.settings = {
  minimumFetchIntervalMillis: 3600000,
  fetchTimeoutMillis: 3600000,
};

remoteConfig.defaultConfig = {};

export async function fetchAndActivateRemoteConfig() {
  // @ts-ignore
  await remoteConfig.fetchAndActivate(0);
}