import { makeStyles } from "@material-ui/core/styles";
const componentStyles = makeStyles((theme) => ({
  bgDefault: {
    background: "#f8f9fe",
  },
  loginContainer: {
    alignSelf: "center",
  },
}));

export default componentStyles;
