import React from "react";
// @material-ui/core components

import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons components
import Email from "@material-ui/icons/Email";
import Lock from "@material-ui/icons/Lock";

// core components
import componentStyles from "./style";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { ConsoleHelper } from "../../../utils/helpers";
import { loginAction } from "./action";
import ForgotPasswordDialog from "../../../components/ForgotPasswordDialog/ForgotPasswordDialog";

function Login(): JSX.Element {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const openForgotPasswordDialog = () => {
    setOpen(true);
  };

  const dispatch = useDispatch();
  const classes = componentStyles();
  const theme = useTheme();

  const initialValues = {
    email: "",
    password: "",
  };
  const yupSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email address is not in valid format")
      .required("Email address is required"),
    password: Yup.string().required("Password is required"),
  });
  const login = (values: any) => {
    ConsoleHelper(values);
    dispatch(loginAction(values.email, values.password));
  };

  return (
    <>
      <Grid item xs={12} lg={5} md={7}>
        <Card classes={{ root: classes.cardRoot }}>
          <CardContent classes={{ root: classes.cardContent }}>
            <Box
              color={theme.palette.grey[600]}
              textAlign="center"
              marginBottom="1rem"
              fontSize="1.4rem"
            >
              <Box fontSize="80%" fontWeight="600" component="small">
                Social Motion GIS
              </Box>
            </Box>
            <Formik
              initialValues={initialValues}
              validationSchema={yupSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                login(values);
                setSubmitting(false);
                resetForm({});
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                resetForm,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <FilledInput
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="email"
                      value={values.email}
                      id="email"
                      autoComplete="off"
                      type="email"
                      placeholder="Email"
                      startAdornment={
                        <InputAdornment position="start">
                          <Email />
                        </InputAdornment>
                      }
                    />
                    {errors.email && touched.email && (
                      <div id="type-error" className={classes.error}>
                        {errors.email}
                      </div>
                    )}
                  </FormControl>
                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <FilledInput
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="password"
                      value={values.password}
                      id="password"
                      autoComplete="off"
                      type="password"
                      placeholder="Password"
                      startAdornment={
                        <InputAdornment position="start">
                          <Lock />
                        </InputAdornment>
                      }
                    />
                    {errors.password && touched.password && (
                      <div id="type-error" className={classes.error}>
                        {errors.password}
                      </div>
                    )}
                  </FormControl>

                  <Box
                    textAlign="center"
                    marginTop="1.5rem"
                    marginBottom="1.5rem"
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Sign in
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </CardContent>
        </Card>
        <Grid container component={Box} marginTop="1rem">
          <Grid item xs={6} component={Box} textAlign="left">
            <a
              onClick={openForgotPasswordDialog}
              className={classes.footerLinks}
            >
              Forgot password
            </a>
          </Grid>
        </Grid>
      </Grid>
      <ForgotPasswordDialog
        open={open}
        handleClose={handleClose}
      ></ForgotPasswordDialog>
    </>
  );
}

export default Login;
