import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Provider } from "react-redux";
import configureStore from "./store";
// @ts-ignore
import App from "./App";
import { ConnectedRouter } from "connected-react-router";
import history from "./utils/history";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import theme from "./utils/styles/theme";

const store = configureStore();

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Provider store={store}>
      <ConnectedRouter history={history}>
      <App />
      </ConnectedRouter>
    </Provider>
    </ThemeProvider>,
  
  document.getElementById("root")
);
