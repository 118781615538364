/* eslint-disable  @typescript-eslint/no-explicit-any */
import { call, put, take, takeEvery } from "redux-saga/effects";
import firebase from "firebase/app";
import { API_CLOUD } from "../../../utils/apiEndPoints";
import { eventChannel } from "redux-saga";
import axios from "axios";
import {
  homePageActionTypes,
  getCategoriesSuccessAction,
  getCategoriesFailureAction,
  AddCategoryAction,
  addCategorySuccessAction,
  addCategoryFailureAction,
  DeleteCategoryAction,
  deleteCategorySuccessAction,
  deleteCategoryFailureAction,
} from "./action";
import { firestore, auth } from "../../../utils/firebaseConnector";
import {
  FIRESTORE_CATEGORIES_COLLECTION,
  FIRESTORE_GEO_JSONS_COLLECTION,
} from "../../../utils/firestoreCollections";
import { ConsoleHelper } from "../../../utils/helpers";
import { categoryMapper } from "../../../utils/mapper/categoryMapper";

function* getAllCategories() {
  ConsoleHelper("getAllCategories fired");
  const ref = firestore
    .collection(FIRESTORE_CATEGORIES_COLLECTION)
    .orderBy("updatedAt", "desc");
  const channel = eventChannel<firebase.firestore.QuerySnapshot>((emit) =>
    ref.onSnapshot(emit)
  );

  try {
    while (true) {
      const data: firebase.firestore.QuerySnapshot = yield take(channel);
      const result = data.docs.map((doc: any) => {
        return categoryMapper(doc);
      });
      yield put(getCategoriesSuccessAction(result));
    }
  } catch (err) {
    yield put(getCategoriesFailureAction(err.toString()));
    channel.close();
  }
}

function* addCategory(action: AddCategoryAction) {
  try {
    try {
      yield call(addCategoryAsync, action.name);
      yield put(addCategorySuccessAction());
    } catch (error) {
      ConsoleHelper("err occured");
      ConsoleHelper(error);
      yield put(addCategoryFailureAction(error.toString()));
    }
  } catch (err) {
    ConsoleHelper("err occured");
    ConsoleHelper(err);
    yield put(addCategoryFailureAction(err.toString()));
  }
}

async function addCategoryAsync(name: string) {
  const user = auth.currentUser;
  const token = user && (await user.getIdToken());
  const payloadHeader = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    API_CLOUD + "/categories",
    {
      name: name,
    },
    payloadHeader
  );
  ConsoleHelper(response);
}

function* deleteCategory(action: DeleteCategoryAction) {
  try {
    try {
      yield call(deleteCategoryAsync, action.categoryId);
      yield put(deleteCategorySuccessAction());
    } catch (error) {
      ConsoleHelper("err occured");
      ConsoleHelper(error);
      yield put(deleteCategoryFailureAction(error.toString()));
    }
  } catch (err) {
    ConsoleHelper("err occured");
    ConsoleHelper(err);
    yield put(deleteCategoryFailureAction(err.toString()));
  }
}

async function deleteCategoryAsync(id: string) {
  await firestore.collection(FIRESTORE_CATEGORIES_COLLECTION).doc(id).delete();
  const deletedGeoJsons = await firestore
    .collection(FIRESTORE_GEO_JSONS_COLLECTION)
    .where("categoryId", "==", id)
    .get();
  const batch = firestore.batch();
  deletedGeoJsons.forEach((doc) => {
    batch.delete(doc.ref);
  });
  await batch.commit();
}

function* getHomePageManagementSagas(): any {
  yield takeEvery(homePageActionTypes.GET_CATEGORIES, getAllCategories);
  yield takeEvery(homePageActionTypes.ADD_CATEGORY, addCategory);
  yield takeEvery(homePageActionTypes.DELETE_CATEGORY, deleteCategory);
}

const homePageSagas = [getHomePageManagementSagas];

export default homePageSagas;
