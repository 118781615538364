import { Button, DialogActions, FormLabel, Grid } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import formStyle from "./formStyle";
import Dropzone from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { DashboardAppState } from "../../store";
import { HomePageState } from "../../containers/admin/Home/reducer";
import { OutlinedInput } from "@material-ui/core";
import {
  addGeoJsonAction,
  setGPXFileAction,
  setImageAction,
} from "../../containers/admin/GeoJsonsPage/action";
import { GeoJsonsPageState } from "../../containers/admin/GeoJsonsPage/reducer";

export default function GeoJsonForm(props: { handleClose: any }): JSX.Element {
  const { handleClose } = props;
  const dispatch = useDispatch();

  const { currentCategory } = useSelector<DashboardAppState, HomePageState>(
    (state) => state.homePageManagement
  );
  const { uploadedFile, uploadedImage } = useSelector<
    DashboardAppState,
    GeoJsonsPageState
  >((state) => state.geoJsonsPageManagement);

  const classes = formStyle();
  const initialValues = {
    title: "",
    type: "",
    description: "",
  };

  const yupSchema = Yup.object().shape({
    title: Yup.string().required("Name is required"),
    type: Yup.string().required("Type is required"),
    description: Yup.string().required("description is required"),
  });

  const changeFile = (files: Array<File>) => {
    dispatch(setGPXFileAction(files[0]));
  };

  const changePhoto = (files: Array<File>) => {
    dispatch(setImageAction(files[0]));
  };

  const addNewGeoJson = (values: any) => {
    handleClose();
    if (currentCategory !== undefined) {
      dispatch(
        addGeoJsonAction(
          values.title,
          values.type,
          currentCategory.documentID,
          values.description,
          uploadedFile,
          uploadedImage,
          uploadedFile?.name.split('.').pop(),
        )
      );
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={yupSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          addNewGeoJson(values);
          setSubmitting(false);
          resetForm({});
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid
              container
              justify="center"
              className={classes.gridStyle}
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={6}>
                <FormLabel>Title</FormLabel>
                <OutlinedInput
                  value={values.title || ""}
                  placeholder="Title"
                  required
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="title"
                  id="title"
                />
                {errors.title && touched.title && (
                  <div id="type-error" className={classes.error}>
                    {errors.title}
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormLabel>Type</FormLabel>
                <OutlinedInput
                  value={values.type || ""}
                  placeholder="Type"
                  required
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="type"
                  id="type"
                />
                {errors.type && touched.type && (
                  <div id="type-error" className={classes.error}>
                    {errors.type}
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <FormLabel>Description</FormLabel>
                <OutlinedInput
                  multiline={true}
                  rows={3}
                  placeholder="Description"
                  required
                  fullWidth
                  value={values.description || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="description"
                  id="description"
                />
                {errors.description && touched.description && (
                  <div id="description-error" className={classes.error}>
                    {errors.description}
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid container>
              <p>GPX or KMZ file</p>
              <Grid item xs={12}>
                <Dropzone
                  accept={[".gpx", ".kmz"]}
                  multiple={false}
                  maxFiles={1}
                  onDrop={changeFile}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div>
                      <div {...getRootProps()} className={classes.dropzone}>
                        <input {...getInputProps()} />
                        <p>
                          Drag 'n' drop a gpx or kmz file here, or click to select a
                          file
                        </p>
                      </div>

                      <h6>Uploaded Files:</h6>
                      <p>{uploadedFile?.name}</p>
                    </div>
                  )}
                </Dropzone>
              </Grid>
            </Grid>

            <Grid container>
              <p>Photo</p>
              <Grid item xs={12}>
                <Dropzone
                  accept="image/*"
                  multiple={false}
                  maxFiles={1}
                  onDrop={changePhoto}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div>
                      <div {...getRootProps()} className={classes.dropzone}>
                        <input {...getInputProps()} />
                        <p>
                          Drag 'n' drop a Image file here, or click to select a
                          file
                        </p>
                      </div>

                      <h6>Uploaded Image:</h6>
                      <p>{uploadedImage?.name}</p>
                    </div>
                  )}
                </Dropzone>
              </Grid>
            </Grid>

            <DialogActions>
              <Button
                onClick={handleClose}
                color="secondary"
                variant="contained"
              >
                Close
              </Button>
              <Button
                type="submit"
                disabled={
                  isSubmitting ||
                  uploadedFile === null ||
                  uploadedImage === null
                }
                color="primary"
                variant="contained"
              >
                Add
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </div>
  );
}
