/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["draft"] }] */
import produce from "immer";
import { toast } from "react-toastify";
import { geoJsonsPageActions, geoJsonsPageActionTypes } from "./action";
import Toast from "light-toast";
export interface GeoJson {
  documentID: string;
  title: string;
  description: string;
  type: string;
  categoryId: string;
  photo: string;
  json: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
}

export interface GeoJsonsPageState {
  geoJsons: Array<GeoJson>;
  getGeoJsonsLoading: boolean;
  addGeoJsonLoading: boolean;
  addGeoJsonError: { hasError: boolean; description: string };
  getGeoJsonsError: { hasError: boolean; description: string };
  uploadedFile: any;
  uploadedImage: any;
  selectedType: string;
  filteredGeoJsons: Array<GeoJson>;
}

const initialState: GeoJsonsPageState = {
  geoJsons: [],
  getGeoJsonsLoading: true,
  addGeoJsonError: { hasError: false, description: "" },
  addGeoJsonLoading: false,
  getGeoJsonsError: { hasError: false, description: "" },
  uploadedFile: null,
  uploadedImage: null,
  selectedType: "",
  filteredGeoJsons: [],
};

export default function geoJsonsPageReducer(
  state: GeoJsonsPageState = initialState,
  action: geoJsonsPageActions
): GeoJsonsPageState {
  return produce(state, (draft) => {
    switch (action.type) {
      case geoJsonsPageActionTypes.GET_GEO_JSONS: {
        draft.getGeoJsonsLoading = true;
        Toast.loading("Loading... Please wait.");
        break;
      }
      case geoJsonsPageActionTypes.GET_GEO_JSONS_SUCCESS: {
        draft.getGeoJsonsLoading = false;
        draft.getGeoJsonsError = { hasError: false, description: "" };
        draft.geoJsons = action.geoJsons;
        if (draft.selectedType === "") {
          draft.filteredGeoJsons = draft.geoJsons;
        } else {
          draft.filteredGeoJsons = draft.geoJsons.filter((geoJson) =>
            geoJson.type
              .toLowerCase()
              .includes(draft.selectedType.toLowerCase())
          );
        }

        Toast.hide();

        break;
      }
      case geoJsonsPageActionTypes.GET_GEO_JSONS_FAILURE: {
        draft.getGeoJsonsLoading = false;
        draft.getGeoJsonsError = { hasError: true, description: action.err };
        Toast.hide();
        toast.error(action.err);
        break;
      }
      case geoJsonsPageActionTypes.ADD_GEO_JSON: {
        draft.addGeoJsonLoading = true;
        Toast.loading("Loading... Please wait.");
        break;
      }
      case geoJsonsPageActionTypes.ADD_GEO_JSON_SUCCESS: {
        draft.addGeoJsonLoading = false;
        Toast.hide();
        toast.success("Geo json added successfully");
        draft.uploadedFile = null;
        draft.uploadedImage = null;
        break;
      }
      case geoJsonsPageActionTypes.ADD_GEO_JSON_FAILURE: {
        draft.addGeoJsonLoading = false;
        draft.addGeoJsonError = { hasError: true, description: action.err };
        Toast.hide();
        toast.error(action.err);
        draft.uploadedFile = null;
        draft.uploadedImage = null;
        break;
      }
      case geoJsonsPageActionTypes.SET_GPX_FILES: {
        draft.uploadedFile = action.file;
        break;
      }

      case geoJsonsPageActionTypes.SET_IMAGE_FILE: {
        draft.uploadedImage = action.image;
        break;
      }

      case geoJsonsPageActionTypes.DELETE_GEO_JSON: {
        Toast.loading("Loading... Please wait.");
        break;
      }
      case geoJsonsPageActionTypes.DELETE_GEO_JSON_SUCCESS: {
        Toast.hide();
        toast.success("Geo json deleted successfully");

        break;
      }
      case geoJsonsPageActionTypes.DELETE_GEO_JSON_FAILURE: {
        Toast.hide();
        toast.error(action.err);

        break;
      }
      case geoJsonsPageActionTypes.EDIT_GEO_JSON: {
        Toast.loading("Loading... Please wait.");
        break;
      }
      case geoJsonsPageActionTypes.EDIT_GEO_JSON_SUCCESS: {
        Toast.hide();
        toast.success("Geo json updated successfully");
        break;
      }
      case geoJsonsPageActionTypes.EDIT_GEO_JSON_FAILURE: {
        Toast.hide();
        toast.error(action.err);

        break;
      }
      case geoJsonsPageActionTypes.CHANGE_GEO_JSON_TYPE: {
        draft.selectedType = action.geoJsonType;
        if (draft.selectedType === "") {
          draft.filteredGeoJsons = draft.geoJsons;
        } else {
          draft.filteredGeoJsons = draft.geoJsons.filter((geoJson) =>
            geoJson.type
              .toLowerCase()
              .includes(draft.selectedType.toLowerCase())
          );
        }
        break;
      }

      default:
        break;
    }
  });
}
