import { Dialog,  DialogContent, Typography, IconButton } from "@material-ui/core";
import GeoJsonForm from "../GeoJsonForm/GeoJsonForm";
import componentStyles from "../ForgotPasswordDialog/dialog.js";
import { Clear } from "@material-ui/icons";
export default function AddNewGeoJsonDialog(props: { open: boolean, handleClose: any }): JSX.Element {
  const classes = componentStyles();
  const { open, handleClose } = props;
  return (
    <div>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={classes.dialogHeader}>
          <Typography
            variant="h5"
            component="h5"
            className={classes.dialogTitle}
          >
            Add New Geo Json
          </Typography>
          <IconButton onClick={handleClose}>
            <Clear />
          </IconButton>
        </div>


        <DialogContent>
          <GeoJsonForm handleClose={handleClose}></GeoJsonForm>
        </DialogContent>
        

      </Dialog>
    </div>
  );
}
