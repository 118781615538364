import React from "react";
import {
  useLocation,
  Route,
  Switch,
  Redirect,
  RouteComponentProps,
} from "react-router-dom";
// @material-ui/core components

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";

// core components

import componentStyles from "./style";
import { StaticContext } from "react-router";
import Sidebar from "../../components/Sidebar/Sidebar";
import NavbarDropdown from "../../components/Dropdowns/NavbarDropdown";
import routes from "../../routes";
import AdminNavbar from "../../components/Navbars/AdminNavbar";

const Admin = (props: any) => {
  const classes = componentStyles();
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    // document.scrollingElement.scrollTop = 0;
    // mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes: any[]) => {
    return routes.map(
      (
        prop: {
          layout: string;
          path: any;
          component:
            | React.ComponentType<any>
            | React.ComponentType<
                RouteComponentProps<any, StaticContext, unknown>
              >
            | undefined;
        },
        key: React.Key | null | undefined
      ) => {
        if (prop.layout === "/admin") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        } else {
          return null;
        }
      }
    );
  };
  return (
    <>
      <>
        <Sidebar
          routes={routes}
          logo={{
            innerLink: "/admin/categories",
            imgSrc: require("../../assets/img/brand/argon-react.png").default,
            imgAlt: "...",
          }}
          dropdown={<NavbarDropdown />}
          
        />
        <Box position="relative" className={classes.mainContent}>
          <AdminNavbar />
          <Switch>
            {getRoutes(routes)}
            <Redirect from="*" to="/admin/categories" />
          </Switch>
          <Container         
            component={Box}
          >
          </Container>
        </Box>
      </>
    </>
  );
};

export default Admin;
