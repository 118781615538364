import { Category } from "../../containers/admin/Home/reducer";
import { convertUTCDateToLocalDate } from "../dateOperations";


export function categoryMapper(doc: any): Category {
  const { name,
    createdBy,
    createdAt,
    updatedAt, } = doc.data();
  return {
    documentID: doc.id,
    name :name,
    createdBy:createdBy,
    createdAt : convertUTCDateToLocalDate(createdAt?.seconds * 1000 || 0),
    updatedAt: convertUTCDateToLocalDate(updatedAt?.seconds * 1000 || 0), 
  };
}