import { makeStyles } from "@material-ui/core/styles";
import { ERROR_HIGH } from "../../utils/colorCodes";
const componentStyles = makeStyles((theme) => ({
  dialogHeader: {
    display: "flex",
    padding: "1.25rem",
    borderTopLeftRadius: ".4375rem",
    borderTopRightRadius: ".4375rem",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dialogTitle: {
    fontSize: "1.0625rem",
    marginBottom: "0",
  },
  formControl: {
    width: "80%",
  },
  error: {
    color: ERROR_HIGH,
    fontSize: "12px",
  },
  gridStyle: {
    maxWidth: 600,
    paddingBottom: "20px",
  },
}));

export default componentStyles;
