import React from "react";
import { useLocation, Route, Switch, Redirect, RouteComponentProps } from "react-router-dom";
// @material-ui/core components

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

// core components




import componentStyles from "./style";
import { StaticContext } from "react-router";
import routes from "../../routes";
import AuthHeader from "../../components/Headers/AuthHeader/AuthHeader";
import { ToastContainer } from "react-toastify";


const Auth = (props:any) => {
  const classes = componentStyles();
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add(classes.bgDefault);
    return () => {
      document.body.classList.remove(classes.bgDefault);
    };
  });
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    // document.scrollingElement.scrollTop = 0;
    // mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes: any[]) => {
    return routes.map((prop: { layout: string; path: any; component: React.ComponentType<any> | React.ComponentType<RouteComponentProps<any, StaticContext, unknown>> | undefined; }, key: React.Key | null | undefined) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="main-content" ref={mainContent}>
       
        <AuthHeader />
       
        <Container
        className={classes.loginContainer}
          component={Box}
          // maxWidth="xl"
          marginTop="-15rem"
          paddingBottom="3rem"
          position="relative"
          zIndex="101"
        >
          <Grid container justify="center">
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/auth/login" />
            </Switch>
          </Grid>
          <ToastContainer position="top-left" />
        </Container>
        
      </div>
     
    </>
  );
};

export default Auth;
