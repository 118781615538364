/* eslint-disable  @typescript-eslint/no-explicit-any */
import { call, put, take, takeEvery } from "redux-saga/effects";
import firebase from "firebase/app";
import { API_CLOUD } from "../../../utils/apiEndPoints";
import { eventChannel } from "redux-saga";
import { storageRef, auth } from "../../../utils/firebaseConnector";
import axios from "axios";
import {
  getGeoJsonsFailureAction,
  getGeoJsonsSuccessAction,
  AddGeoJsonAction,
  addGeoJsonSuccessAction,
  addGeoJsonFailureAction,
  geoJsonsPageActionTypes,
  GetGeoJsonsAction,
  DeleteGeoJsonAction,
  deleteGeoJsonFailureAction,
  deleteGeoJsonSuccessAction,
  EditGeoJsonAction,
  editGeoJsonSuccessAction,
  editGeoJsonFailureAction,
} from "./action";
import { firestore } from "../../../utils/firebaseConnector";
import { FIRESTORE_GEO_JSONS_COLLECTION } from "../../../utils/firestoreCollections";
import { geoJsonMapper } from "../../../utils/mapper/geoJsonMapper";
import { ConsoleHelper } from "../../../utils/helpers";

function* getAllGeoJsons(action: GetGeoJsonsAction) {
  ConsoleHelper("getAllGeoJsons fired");
  ConsoleHelper(action.categoryId);
  const ref = firestore
    .collection(FIRESTORE_GEO_JSONS_COLLECTION)
    .where("categoryId", "==", action.categoryId)
    .orderBy("updatedAt", "desc");
  const geoJsonChannel = eventChannel<firebase.firestore.QuerySnapshot>(
    (emit) => ref.onSnapshot(emit)
  );

  try {
    while (true) {
      const data: firebase.firestore.QuerySnapshot = yield take(geoJsonChannel);
      const result = data.docs.map((doc: any) => {
        return geoJsonMapper(doc);
      });
      yield put(getGeoJsonsSuccessAction(result));
    }
  } catch (err) {
    yield put(getGeoJsonsFailureAction((err as any).toString()));
    geoJsonChannel.close();
  }
}

function* addGeoJson(action: AddGeoJsonAction) {
  try {
    try {
      yield call(
        addGeoJsonAsync,
        action.title,
        action.geoType,
        action.categoryId,
        action.description,
        action.file,
        action.image,
        action.fileType,
      );
      yield put(addGeoJsonSuccessAction());
    } catch (error) {
      ConsoleHelper("err occured");
      ConsoleHelper(error);
      yield put(addGeoJsonFailureAction((error as any).toString()));
    }
  } catch (err) {
    ConsoleHelper("err occured");
    ConsoleHelper(err);
    yield put(addGeoJsonFailureAction((err as any).toString()));
  }
}

function* editGeoJson(action: EditGeoJsonAction) {
  try {
    try {
      yield call(editGeoJsonAsync, action.geoJsonId, action.categoryId, action.title, action.geoType, action.description,);
      yield put(editGeoJsonSuccessAction());
    } catch (error) {
      ConsoleHelper("err occured");
      ConsoleHelper(error);
      yield put(editGeoJsonFailureAction((error as any).toString()));
    }
  } catch (err) {
    ConsoleHelper("err occured");
    ConsoleHelper(err);
    yield put(editGeoJsonFailureAction((err as any).toString()));
  }
}

function* deleteGeoJson(action: DeleteGeoJsonAction) {
  try {
    try {
      yield call(deleteGeoJsonAsync, action.geoJsonId);
      yield put(deleteGeoJsonSuccessAction());
    } catch (error) {
      ConsoleHelper("err occured");
      ConsoleHelper(error);
      yield put(deleteGeoJsonFailureAction((error as any).toString()));
    }
  } catch (err) {
    ConsoleHelper("err occured");
    ConsoleHelper(err);
    yield put(deleteGeoJsonFailureAction((err as any).toString()));
  }
}

async function deleteGeoJsonAsync(id: string) {
  await firestore.collection(FIRESTORE_GEO_JSONS_COLLECTION).doc(id).delete();
}

async function editGeoJsonAsync(geoJsonId: string, categoryId: string, title: string,geoType: string, description: string) {
  await firestore
    .collection(FIRESTORE_GEO_JSONS_COLLECTION)
    .doc(geoJsonId)
    .update({ categoryId: categoryId,title: title,type: geoType,description: description, });
}

async function addGeoJsonAsync(
  title: string,
  geoType: string,
  category: string,
  description: string,
  file: any,
  image: any,
  fileType: string,
) {
  ConsoleHelper("add geo json function fired");
  const miliseconds = Date.now();
  const ref = storageRef.child(`images/${miliseconds}${image.name}`);
  ConsoleHelper(image);
  ConsoleHelper(file);
  const imageUploadTask = await ref.put(image);

  ConsoleHelper("file uploaded");
  const imageUrl = await imageUploadTask.ref.getDownloadURL();

  const uploadTask = await storageRef
    .child(`gpxFiles/${miliseconds}${file.path}`)
    .put(file);
  ConsoleHelper("file uploaded");
  const downloadURL = await uploadTask.ref.getDownloadURL();
  ConsoleHelper(downloadURL);
  const user = auth.currentUser;
  const token = user && (await user.getIdToken());
  const payloadHeader = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    API_CLOUD + "/geoJsons",
    {
      title: title,
      type: geoType,
      photo: imageUrl,
      categoryId: category,
      description: description,
      filePath: downloadURL,
      fileType: fileType,
    },
    payloadHeader
  );
  ConsoleHelper(response);
}

function* getGeoJsonPageManagementSagas(id: string): any {
  yield takeEvery(geoJsonsPageActionTypes.GET_GEO_JSONS, getAllGeoJsons);
  yield takeEvery(geoJsonsPageActionTypes.ADD_GEO_JSON, addGeoJson);
  yield takeEvery(geoJsonsPageActionTypes.DELETE_GEO_JSON, deleteGeoJson);
  yield takeEvery(geoJsonsPageActionTypes.EDIT_GEO_JSON, editGeoJson);
}

const geoJsonsPageSagas = [getGeoJsonPageManagementSagas];

export default geoJsonsPageSagas;
