import { makeStyles } from "@material-ui/core/styles";

const componentStyles = makeStyles((theme) => ({
  mainContent: {
    // [theme.breakpoints.up("md")]: {
    //   marginLeft: "250px",
    // },
  },
  containerRoot: {
    // [theme.breakpoints.up("md")]: {
    //   paddingLeft: "39px",
    //   paddingRight: "39px",
    // },
  },
}));

export default componentStyles;
