import React from "react";
import Container from "@material-ui/core/Container";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./styles";
import { getGeoJsonsAction } from "./action";
import { DashboardAppState } from "../../../store";
import { GeoJsonsPageState } from "./reducer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box } from "@material-ui/core";
import Header from "../../../components/Headers/Header/Header";
import GeoJsonsTable from "../../../components/Tables/geoJsonsTable";
import { ConsoleHelper } from "../../../utils/helpers";
import { HomePageState } from "../Home/reducer";

export default function GeoJsonsPage(): JSX.Element {
  const { currentCategory } = useSelector<DashboardAppState, HomePageState>(
    (state) => state.homePageManagement
  );
  ConsoleHelper("Geo jsons page loaded");
  let id: string = "";
  if (currentCategory !== undefined) {
    id = currentCategory.documentID;
  }

  const dispatch = useDispatch();
  const classes = useStyles();

  const { filteredGeoJsons, getGeoJsonsLoading } = useSelector<
    DashboardAppState,
    GeoJsonsPageState
  >((state) => state.geoJsonsPageManagement);

  React.useEffect(() => {
    dispatch(getGeoJsonsAction(id));
  }, [dispatch, id]);

  if (getGeoJsonsLoading) {
    return (
      <div>
        <Header />
        {/* Page content */}
        <Container
          // maxWidth={false}
          component={Box}
          marginTop="-18rem"
          classes={{ root: classes.containerRoot }}
        >
          <ToastContainer position="top-left" />
        </Container>
      </div>
    );
  }
  return (
    <div>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
      
        classes={{ root: classes.containerRoot }}
      >
        <ToastContainer position="top-left" />
        <GeoJsonsTable geoJsons={filteredGeoJsons} />
        <div style={{ height: 30 }} />
      </Container>
    </div>
  );
}
