

// const dateFormat = require("dateformat");


export function convertUTCDateToLocalDate(milliseconds: number): string {
  const date = new Date(milliseconds);
  return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000).toISOString().slice(0, 16);
}

// export function getLocalDate(date: Date): string {
//   return dateFormat(date, "yyyy-mm-dd");
// }

export function secondsToTime(start: number, end: number) {
  const dateObj = new Date(Math.abs(start - end) * 1000);
  const hours = dateObj.getUTCHours();
  const minutes = dateObj.getUTCMinutes();
  const seconds = dateObj.getSeconds();

  const h = hours.toString().padStart(2, "0");
  const m = minutes.toString().padStart(2, "0");
  const s = seconds.toString().padStart(2, "0");

  if (h === "00") {
    if (m === "00") {
      return `${s} sec`;
    }
    return `${m} min: ${s} sec`;
  }
  return `${h} h: ${m} min: ${s} sec`;
}


// export function getFormattedDate(input: number | string): string {
//   try {
//     const date = new Date(input);
//     return dateFormat(date, "dd/mm/yyyy");
//   } catch (e) {
//     return "-";
//   }
// }

// export function getFormattedDateTime(input: number | string | Date): string {
//   try {
//     const date = new Date(input);
//     return dateFormat(date, "dd/mm/yyyy hh:MM TT");
//   } catch (e) {
//     return "-";
//   }
// }

export function getPreviousPeriod(startDate: Date, endDate: Date) {
  const previousStartDate = new Date(startDate.getTime() * 2 - endDate.getTime() - 1000);
  const previousEndDate = new Date(startDate.getTime() - 1000);
  return { previousStartDate, previousEndDate };
}
