// import { NotificationManager } from "react-notifications";

import { ConsoleHelper } from "../../../utils/helpers";
import { Category } from "./reducer";

export enum homePageActionTypes {
  GET_CATEGORIES = "home/get_categories",
  GET_CATEGORIES_SUCCESS = "home/get_categories_success",
  GET_CATEGORIES_FAILURE = "home/get_categories_failure",
  ADD_CATEGORY = "home/add_category",
  ADD_CATEGORY_SUCCESS = "home/add_category_success",
  ADD_CATEGORY_FAILURE = "home/add_category_failure",
  SET_CURRENT_CATEGORY = "home/set_current_category",
  DELETE_CATEGORY = "home/delete_category",
  DELETE_CATEGORY_SUCCESS = "home/delete_category_success",
  DELETE_CATEGORY_FAILURE = "home/delete_category_failure",
}

export interface DeleteCategoryAction {
  type: homePageActionTypes.DELETE_CATEGORY;
  categoryId: string;
}

export interface DeleteCategorySuccessAction {
  type: homePageActionTypes.DELETE_CATEGORY_SUCCESS;
}

export interface DeleteCategoryFailureAction {
  type: homePageActionTypes.DELETE_CATEGORY_FAILURE;
  err: string;
}

export interface GetCategoriesAction {
  type: homePageActionTypes.GET_CATEGORIES;
}

interface GetCategoriesSuccessAction {
  type: homePageActionTypes.GET_CATEGORIES_SUCCESS;
  categories: Array<Category>;
}

interface GetCategoriesFailureAction {
  type: homePageActionTypes.GET_CATEGORIES_FAILURE;
  err: string;
}

export interface AddCategoryAction {
  type: homePageActionTypes.ADD_CATEGORY;
  name: string;
}

interface AddCategorySuccessAction {
  type: homePageActionTypes.ADD_CATEGORY_SUCCESS;
}

interface AddCategoryFailureAction {
  type: homePageActionTypes.ADD_CATEGORY_FAILURE;
  err: string;
}
interface SetCurrentCategoryAction {
  type: homePageActionTypes.SET_CURRENT_CATEGORY;
  category: Category;
}

export type HomePageActions =
  | GetCategoriesAction
  | GetCategoriesSuccessAction
  | GetCategoriesFailureAction
  | AddCategoryAction
  | AddCategorySuccessAction
  | SetCurrentCategoryAction
  | DeleteCategoryAction
  | DeleteCategorySuccessAction
  | DeleteCategoryFailureAction
  | AddCategoryFailureAction;

export function deleteCategoryAction(id: string): DeleteCategoryAction {
  return {
    type: homePageActionTypes.DELETE_CATEGORY,
    categoryId: id,
  };
}
export function deleteCategorySuccessAction(): DeleteCategorySuccessAction {
  return {
    type: homePageActionTypes.DELETE_CATEGORY_SUCCESS,
  };
}

export function deleteCategoryFailureAction(
  err: string
): DeleteCategoryFailureAction {
  return {
    type: homePageActionTypes.DELETE_CATEGORY_FAILURE,
    err: err,
  };
}

export function getCategoriesAction(): GetCategoriesAction {
  ConsoleHelper("GetCategoriesAction fired");
  return {
    type: homePageActionTypes.GET_CATEGORIES,
  };
}

export function getCategoriesSuccessAction(
  categories: Array<Category>
): GetCategoriesSuccessAction {
  return {
    type: homePageActionTypes.GET_CATEGORIES_SUCCESS,
    categories: categories,
  };
}

export function getCategoriesFailureAction(
  err: string
): GetCategoriesFailureAction {
  // eslint-disable-next-line no-console
  console.error("getCategoriesFailureAction", err);
  return {
    type: homePageActionTypes.GET_CATEGORIES_FAILURE,
    err: err,
  };
}

export function addCategoryAction(name: string): AddCategoryAction {
  return {
    type: homePageActionTypes.ADD_CATEGORY,
    name: name,
  };
}

export function addCategorySuccessAction(): AddCategorySuccessAction {
  return {
    type: homePageActionTypes.ADD_CATEGORY_SUCCESS,
  };
}

export function addCategoryFailureAction(
  err: string
): AddCategoryFailureAction {
  return {
    type: homePageActionTypes.ADD_CATEGORY_FAILURE,
    err: err,
  };
}

export function setCurrentCategoryAction(
  category: Category
): SetCurrentCategoryAction {
  return {
    type: homePageActionTypes.SET_CURRENT_CATEGORY,
    category: category,
  };
}
