import "./App.css";
import { Redirect, Route, Switch } from "react-router";
import Admin from "./layouts/Admin/Admin";
import Auth from "./layouts/Auth/Auth";
import { auth} from "./utils/firebaseConnector";
import { useEffect, useState } from "react";
// import history from "./utils/history";


function onAuthStateChange(setUser: any): any {
  return auth.onAuthStateChanged((user) => {
    if (user) {
      setUser(user);
      // history.replace("/admin");
    } else {
      setUser(null);
      // history.push("/auth");

    }
  });
}

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChange(setUser);
    return () => {
      unsubscribe();
    };
  }, []);








  return (
    <div className="App">
      <Switch>
        <Route path="/admin" render={(props) => user == null ? <Auth {...props} /> : <Admin {...props} />} />
        <Route path="/auth" render={(props) => user != null ? <Admin {...props} /> : <Auth {...props} />} />
        <Redirect from="/" to="/admin/categories" />


      </Switch>
    </div>
  );
}

export default App;
