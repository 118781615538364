/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["draft"] }] */
import produce from "immer";
import { toast } from "react-toastify";
import { LoginPageActions, loginPageActionTypes } from "./action";
import Toast from 'light-toast';
import { ConsoleHelper } from "../../../utils/helpers";


export interface LoginPageState {
    loginLoading: boolean;
    forgotPasswordLoading: boolean;
    loginError: { hasError: boolean; description: string };
    forgotPasswordError: { hasError: boolean; description: string };
    

}

const initialState: LoginPageState = {
    
    loginLoading: false,
    loginError:{hasError: false, description:""},
    forgotPasswordLoading:false,
    forgotPasswordError: { hasError: false, description: "" },

};

export default function loginPageReducer(state: LoginPageState = initialState, action: LoginPageActions): LoginPageState {
    return produce(state, (draft) => {
        switch (action.type) {
            case loginPageActionTypes.LOGIN: {
                draft.loginLoading = true;
                Toast.loading("Loading... Please wait.");
                break;
            }
            case loginPageActionTypes.LOGIN_SUCCESS: {
                draft.loginLoading = false;
                ConsoleHelper("login successful");
                draft.loginError = { hasError: false, description: "" };
                Toast.hide();
                toast.success("Successfully loggedIn..");
                
                break;
            }
            case loginPageActionTypes.LOGIN_FAILURE: {
                ConsoleHelper("Error login");
                draft.loginLoading = false;
                draft.loginError = { hasError: true, description: action.err };
                Toast.hide();
                toast.error(action.err);
                break;
            }
            case loginPageActionTypes.FORGOT_PASSWORD:{
                draft.forgotPasswordLoading = true;
                Toast.loading("Loading... Please wait.");
                break;
            }
            case loginPageActionTypes.FORGOT_PASSWORD_SUCCESS:{
                draft.forgotPasswordLoading = false;
                Toast.hide();
                toast.success("Password reset link sent successfully");
                break;
            }
            case loginPageActionTypes.FORGOT_PASSWORD_FAILURE:{
                draft.forgotPasswordLoading = false;
                draft.forgotPasswordError = { hasError: true, description: action.err };
                Toast.hide();
                toast.error(action.err);
                break;
            }

            case loginPageActionTypes.LOGOUT:{
                Toast.loading("Loading... Please wait.");
                break;
            }

            case loginPageActionTypes.LOGOUT_SUCCESS:{
                Toast.hide();
                toast.success("Successfully logged out");
                break;
            }
            case loginPageActionTypes.LOGOUT_FAILURE:{
                Toast.hide();
                toast.error(action.err);
                break;
            }
            
            
            default:
                break;
        }
    });
}
