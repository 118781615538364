/* eslint-disable  @typescript-eslint/no-explicit-any */
import { call, put, takeEvery } from "redux-saga/effects";
import { auth } from '../../../utils/firebaseConnector';
import {
  ForgotPasswordAction,
  forgotPasswordFailureAction,
  forgotPasswordSuccessAction,
  LoginAction,
  loginFailureAction,
  loginPageActionTypes,
  loginSuccessAction,
  logoutFailureAction,
  logoutSuccessAction,

} from "./action";
import { ConsoleHelper } from "../../../utils/helpers";



function* login(action: LoginAction) {
  try {
    try {
      ConsoleHelper(action.email);
      yield call(loginAsync,action.email, action.password);
      yield put(loginSuccessAction());
      
    } catch (error) { 
      ConsoleHelper("Error occured");
    yield put(loginFailureAction(error.toString()));
    }
    
    
  } catch (err) {
    ConsoleHelper(err);
    yield put(loginFailureAction(err.toString()));
  }
}

function* forgotPassword(action: ForgotPasswordAction) {
  try {
    try {
      yield call(forgotPasswordAsync,action.email);
      yield put(forgotPasswordSuccessAction());
    } catch (error) { 
      ConsoleHelper(error);
    yield put(forgotPasswordFailureAction(error.toString()));
    }
    
    
  } catch (err) {
    ConsoleHelper(err);
    yield put(forgotPasswordFailureAction(err.toString()));
  }
}

function* logout() {
  ConsoleHelper("logou function called");
  try {
    try {
      yield call(logoutAsync);
      yield put(logoutSuccessAction());
    } catch (error) { 
      ConsoleHelper(error);
    yield put(logoutFailureAction(error.toString()));
    }
    
    
  } catch (err) {
    ConsoleHelper(err);
    yield put(logoutFailureAction(err.toString()));
  }
}


async function loginAsync(email:string, password:string){
  const userCredential = await auth.signInWithEmailAndPassword(email, password);
  ConsoleHelper(userCredential);
}

async function logoutAsync(){
  ConsoleHelper("logou function called");
  await auth.signOut();
}

async function forgotPasswordAsync(email:string){
  await auth.sendPasswordResetEmail(email);
}



function* getLoginPageManagementSagas(): any {
  yield takeEvery(loginPageActionTypes.LOGIN, login);
  yield takeEvery(loginPageActionTypes.FORGOT_PASSWORD, forgotPassword);
  yield takeEvery(loginPageActionTypes.LOGOUT, logout);

}

const loginPageSagas = [getLoginPageManagementSagas];

export default loginPageSagas;
