import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { all, fork } from "redux-saga/effects";
import { routerMiddleware } from "connected-react-router/immutable";
import { connectRouter } from "connected-react-router";
import history from "./utils/history";

import homePageReducer, {
  HomePageState,
} from "./containers/admin/Home/reducer";
import homePageSagas from "./containers/admin/Home";
import loginPageReducer, {
  LoginPageState,
} from "./containers/auth/Login/reducer";
import loginPageSagas from "./containers/auth/Login";
import geoJsonsPageReducer, {
  GeoJsonsPageState,
} from "./containers/admin/GeoJsonsPage/reducer";
import geoJsonsPageSagas from "./containers/admin/GeoJsonsPage";

export interface DashboardAppState {
  homePageManagement: HomePageState;
  loginPageManagement: LoginPageState;
  geoJsonsPageManagement: GeoJsonsPageState;
  router: any;
}

const rootReducer = combineReducers<DashboardAppState>({
  homePageManagement: homePageReducer,
  loginPageManagement: loginPageReducer,
  geoJsonsPageManagement: geoJsonsPageReducer,
  router: connectRouter(history),
});

function* rootSaga() {
  yield all(homePageSagas.map((s: any) => fork(s)));
  yield all(loginPageSagas.map((s: any) => fork(s)));
  yield all(geoJsonsPageSagas.map((s: any) => fork(s)));
}

function configureStore() {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [sagaMiddleware, routerMiddleware(history)];

  const store = createStore(
    rootReducer,
    {},
    compose(applyMiddleware(...middleware))
  );
  sagaMiddleware.run(rootSaga);
  return store;
}

export default configureStore;
