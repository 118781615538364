import { makeStyles } from "@material-ui/core/styles";

const componentStyles = makeStyles((theme) => ({
  header: {
    position: "relative",
    background: "#f8f9fe",
    paddingBottom: "8rem",
    paddingTop: "3rem",
    [theme.breakpoints.up("md")]: {
      paddingTop: "8rem",
    },
  },
  containerRoot: {
    height: "150px",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "39px",
      paddingRight: "39px",
    },
  },
}));

export default componentStyles;
