import React from "react";
import Container from "@material-ui/core/Container";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./styles";
import { getCategoriesAction } from "./action";
import { DashboardAppState } from "../../../store";
import { HomePageState } from "./reducer";
import CategoriesTable from "../../../components/Tables/categoryTable";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Box } from "@material-ui/core";
import Header from "../../../components/Headers/Header/Header";


export default function Dashboard(): JSX.Element {
  
  

  const dispatch = useDispatch();
  const classes = useStyles();

  const { categories, getCategoriesLoading } = useSelector<DashboardAppState, HomePageState>(
    (state) => state.homePageManagement,
  );

  React.useEffect(() => {
   
      dispatch(getCategoriesAction());
    
  }, [dispatch]);

  
  if(getCategoriesLoading){
    return(
      <div>
        <Header />
      {/* Page content */}
      <Container
        // maxWidth={false}
        component={Box}
        marginTop="-18rem"
        classes={{ root: classes.containerRoot }}
      >
        
        <ToastContainer position="top-left" />
         
        </Container>
       
        
      </div>
    );

  }
  return (

    
    
    <div>
      
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
      
        classes={{ root: classes.containerRoot }}
      >
        
        <ToastContainer position="top-left" />
        <CategoriesTable categories={categories} />
          <div style={{ height: 30 }} />
        </Container>
     
    </div>
  );
}


