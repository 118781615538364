import { GeoJson } from "../../containers/admin/GeoJsonsPage/reducer";
import { convertUTCDateToLocalDate } from "../dateOperations";

export function geoJsonMapper(doc: any): GeoJson {
  const {
    title,
    description,
    type,
    categoryId,
    photo,
    json,
    createdBy,
    createdAt,
    updatedAt,
  } = doc.data();
  return {
    documentID: doc.id,
    title: title,
    description: description,
    type: type,
    categoryId: categoryId,
    photo: photo,
    json: json,
    createdBy: createdBy,
    createdAt: convertUTCDateToLocalDate(createdAt?.seconds * 1000 || 0),
    updatedAt: convertUTCDateToLocalDate(updatedAt?.seconds * 1000 || 0),
  };
}
